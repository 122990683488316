@media (min-width: 991px) {
  .healthStatus {
    margin-bottom: 0px;
  }
}

@media (max-width: 990px) {
  .healthStatus {
    margin-bottom: 0px;
    min-width: 0px;
  }
}

@media (min-width: 991px) {
  .dataCenterHeadCell {
    font-size: 15px;
  }
}

@media (min-width: 991px) {
  .monitorHeadCell {
    text-align: center;
    font-size: 10px;
    font-weight: bold;
  }
}

@media (max-width: 990px) {
  .monitorHeadCell {
    text-align: center;
    font-size: 8px;
    font-weight: bold;
  }
}

@media (min-width: 991px) {
  .monitorGrouping {
    padding: 8px 5px;
  }
}

@media (max-width: 990px) {
  .monitorGrouping {
    padding: 8px 5px;
  }
}

@media (min-width: 991px) {
  .tooltipText {
    font-size: 18px;
    font-weight: lighter;
  }
}

@media (max-width: 991px) {
  .tooltipText {
    font-size: 0.75rem;
  }
}

@media (max-width: 990px) {
  .monitorDescriptionText {
    font-size: 11px;
  }
}

@media (min-width: 991px) {
  .monitorDescriptionText {
    font-size: 15px;
  }
}

@media (max-width: 990px) {
  .monitorDescriptionWebsite {
    font-size: 8px;
  }
}

@media (min-width: 991px) {
  .monitorDescriptionWebsite {
    font-size: 11px;
  }
}

.monitorIcon {
  text-align: center;
  vertical-align: middle;
}

.tooltipTextDescription {
  font-weight: bold;
  font-style: normal;
}

.tooltipTextURL {
  font-style: normal;
}

.legendArea {
  width: 100%;
  text-align: center;
}

.legendContainer {
  display: inline-block;
  margin: auto;
}

.legendGrouping {
  display: flex;
  padding: 6px 0px 6px 0px;
}

@media (min-width: 991px) {
  .legend {
    padding-right: 8px;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
  }
}

@media (max-width: 990px) {
  .legend {
    padding-right: 14px;
    font-size: 0.25rem;
    font-style: normal;
    font-weight: bold;
  }
}

@media (min-width: 991px) {
  .legendNA {
    padding-right: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    padding-top: 2px;
  }
}

@media (max-width: 990px) {
  .legendNA {
    padding-right: 15px;
    font-size: 0.25rem;
    font-style: normal;
    font-weight: bold;
  }
}

/* Tooltip Icon Styling for Health Status Icons (SVG) */
.bx--tooltip__trigger path[id="Good-Icon"] {
  fill: #73C9C1;
}

.bx--tooltip__trigger path[id="x"] {
  fill: #b20000;
}

.bx--tooltip__trigger svg circle[id="BackGround-Circle"] {
  fill: #f6ce54;
}

.bx--tooltip__trigger path[id="Combined-Shape"] {
  stroke: #f6ce54;
  fill: #fff;
}

.bx--tooltip__trigger path[id="Partially-Available-Icon-Shape"] {
  fill: orange;
}

.bx--tooltip__trigger path[id="Unknown-Icon-Shape"] {
  fill: #fff;
}

.bx--tooltip__trigger svg g[id="Page-1"],
.bx--tooltip__trigger svg circle[id="Unknown-Circle"] {
  fill: #93A2AA;
}

/* Tooltip Styling*/
.bx--tooltip--icon__bottom:hover::after {
  padding: 1rem;
  word-wrap: break-word;
  max-width: fit-content;
  max-height: fit-content;
  background: #fff;
  color: #152935;
  z-index: 10000;
  -webkit-box-shadow: 0 4px 8px 0 RGB(0 0 0 / 10%);
  box-shadow: 0 4px 8px 0 RGB(0 0 0 / 10%);
}

.bx--tooltip--icon__bottom:before {
  padding: 0.25rem;
  word-wrap: break-word;
  background: #fff;
}

.bx--tooltip--icon__bottom:hover svg {
  filter: brightness(0.9);
}

.healthStatusTab {
  overflow-x: auto;
  overflow-y: hidden;
}

.healthStatusTabOnOverflow {
  height: 4rem;
}

.healthStatusTab > .bx--tabs__nav {
  max-width: none;
}