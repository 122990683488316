* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #f0f3f6;
}

@media (min-width: 991px) {
  .brand-container-title {
    padding: 1.3em;
    float: left;
    margin-right: 6px;
   
  }
}

@media (max-width: 990px) {
  .brand-container-title {
    padding-top: 1em;
    padding-left: 0.5em;
    float: left;
    margin-right: 6px;
  }
}

.brand-container-message {
  padding: 0 1.3em;
  float: right;
}

.brand-titlehead {
  text-align: left;
  font-size: 20px;
  color: white;
  margin-right: 6px;
}
.brand-titlestrong {
  text-align: left;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.brand-tailmessage {
  text-align: left;
  color: white;
  font-size: 20px;
  font-weight: lighter;
  margin-right: 6px;
}

.navbar-nav {
  margin: 7.5px -15px;
}
.navbar-nav > li > a {
  line-height: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}
@media (min-width: 991px) {
.navbar-nav {
  float: left;
  margin: 0;
}
.navbar-nav > li {
  float: left;
}
.navbar-nav > li > a {
  padding-bottom: 15px;
  padding-top: 15px;
  text-decoration: none;
}
.navbar-nav.navbar-right:last-child {
  margin-right: -15px;
}
}

.navbar a {
  color: #fff;
}

.navbar-default .navbar-brand {
  color: #fff;
  display: flex;
  width: '100%';
}

.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color: #fff;
  background-color: transparent;
}

.navbar-nav li a {
  font-size: 17px;
  letter-spacing: 1.2px;
  color: #C8CACC;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s ease 0s;
  line-height: 60px;
  padding: 0 25px;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
text-decoration: none;
color: #eee;
}

.navbar-default .navbar-nav>li>a {
  color: #fff;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: #fff;
}
.navbar-default .navbar-toggle {
  border-color: #1d3649;
}

.navbar-brand, .navbar-nav>li>a {
  text-shadow: 0 1px 0 rgba(255,255,255,.25);
}
@media (max-width: 767px) {
  .hidden-xs {
      display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
      display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1329px) {
  .hidden-md {
      display: none !important;
  }
}
@media (min-width: 1330px) {
  .hidden-lg {
      display: none !important;
  }
}

.spaceBetweenComponents {
  height: 20px;
}

@media (max-width: 990px) {
  .spaceBetweenFilterButtons {
    height: 6px;
  }
}

ol {
  display: block;
  list-style-type: decimal;
  padding-inline-start: 40px;
}

ul {
  display: block;
  list-style-type: disc;
  padding-inline-start: 40px;
}

sub {
	font-size: smaller !important;
	vertical-align: sub;
}

sup {
	vertical-align: super;
	font-size: smaller !important;
}

blockquote {
  display: block;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  border-left: solid 5px #ccc;
}

blockquote p {
  padding-left: 10px;
}

big {
  font-size: larger;
}

small {
  font-size: smaller;
}

tt {
  font-family: monospace;
}

code {
  font-family: monospace;
}

kbd {
  font-family: monospace;
}

samp {
  font-family: monospace;
}

ins {
  text-decoration: underline;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  tab-size: 4;
  font-family: monospace;
  margin: 1em 0px;
}

q {
  quotes: "“" "”" "‘" "’";
}

q::before {
  content: open-quote;
}

q::after {
  content: close-quote;
}

.bx--data-table-v2 tbody {
  border-top: 1px solid #dfe3e6 !important;
  border-bottom: 1px solid #dfe3e6 !important;
}

.bx--data-table-v2 tr:hover td {
  border-top: 1px solid #3d70b2 !important;
  border-bottom: 1px solid #3d70b2 !important;
}

.notificationMessageTD {
  border: 1px solid #dfe3e6;
}

.bx--data-table-v2 tr:hover .notificationMessageTD {
  border: 1px solid #3d70b2;
  
}





