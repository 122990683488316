
.dataTableSkeletonComponent {
  width: 100%;
  padding-top: 12px;
}

.dataTableSkeletonComponent > .bx--skeleton__placeholder {
  width: 100%;
}

@media (max-width: 990px) {
  .bx--data-table-v2-container {
    min-width: 0px;
  }
}

@media (min-width: 991px) {
  .toolbarButtonsRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 990px) {
  .toolbarButtonsRow {
    width: 100%;
  }
}


.bx--search-input {
  background-color: white;
  height: 40px !important;
  font-size: 0.95rem !important;
}

@media (max-width: 990px) {
  .searchBar {
    width: 150%;
    padding-bottom: 6px;
  }
}

.communicationsTableToolbar {
  padding-top: 6px;
  display: inherit !important;
}

@media (min-width: 991px) {
  .toolbarButton {
    font-size: 15px;
    margin-right: 6px;
  }
}

@media (min-width: 991px) {
  .toolbarButton:last-child {
    font-size: 15px;
    margin-right: 0px;
  }
}

@media (max-width: 990px) {
  .toolbarButton {
    width: 100%;
    margin-top: 6px;
  }
}

@media (min-width: 991px) {
  .toolbarAlertButton {
    font-size: 15px;
    background-color: #734098
  }
}

@media (min-width: 991px) {
  .toolbarAlertButton:hover {
    font-size: 15px;
    background-color: #692a96
  }
}

@media (min-width: 991px) {
  .toolbarAlertButton:focus {
    font-size: 15px;
    background-color: #5e1394
  }
}

@media (max-width: 990px) {
  .toolbarAlertButton {
    width: 100%;
    background-color: #734098
  }
}

@media (max-width: 990px) {
  .toolbarAlertButton:hover {
    width: 100%;
    background-color: #692a96
  }
}

@media (max-width: 990px) {
  .toolbarAlertButton:focus {
    width: 100%;
    background-color: #5e1394
  }
}

.timePickerArea {
  display: inline-block;
  padding-top: 4px;
}

.timePickerModalArea {
  display: inline-block;
}

@media (min-width: 991px) {
  .radioButtonArea {
    padding: 12px 0px 6px 0px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 990px) {
  .radioButtonArea {
    padding: 12px 0px 6px 0px;
    display: block;
  }
}

.radioButton {
  display: inline-block;
  margin: 6px;
}

@media (max-width: 990px) {
  .bx--radio-button__label {
    font-size: 16px;
  }
}

@media (min-width: 991px) {
  .headerCellTitle {
    font-size: 15px;
    font-weight: bold;
    border: none;
    display: flex;
    align-items: center;
    background-color: transparent;
    text-align: left;
  }
}

@media (max-width: 990px) {
  .headerCellTitle {
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    border: none;
    display: flex;
    align-items: center;
    background-color: transparent;
  }
}

.headerCell {
  width: 25%;
  font-size: 15px;
  font-weight: bold;
  border: none;
  background-color: transparent;
  text-align: left;
  padding: 1rem;
}

.headerCell > button {
  display: contents;
}

.bx--table-header-label {
  padding-right: 0.5rem;
}

@media (min-width: 991px) {
  .cellTitle {
    font-size: 16px;
    padding: 12px 0px 12px 0px;
    font-weight: normal;
  }
}

@media (max-width: 990px) {
  .cellTitle {
    font-size: 16px;
    padding: 16px 0px 16px 0px;
    font-weight: normal;
  }
}

.cellDateText {
  font-size: 13px;
}

.cellContent {
  padding-top: 6px;
  padding-bottom: 6px;
}

.bannerTag {
  color: white;
}

.expandedArea {
  padding: 12px 36px 12px 36px;
  background-color: white;
  border-bottom: 1px solid #dfe3e6;
}

.expandedRow {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.modalCellDateArea {
  padding: 6px 0px 6px 0px;
}

@media (min-width: 991px) {
  .expandedCategoryTitle {
    font-size: 16px;
    font-weight: 500;
    font-style: bold;
    font-family: IBM Plex Sans;
    color: #3d70b2;
  }
}

@media (max-width: 990px) {
  .expandedCategoryTitle {
    font-size: 13px;
    font-weight: 500;
    font-style: bold;
    font-family: IBM Plex Sans;
    color: #3d70b2;
    white-space: pre-wrap;
  }
}

@media (min-width: 991px) {
  .expandedCategoryText {
    font-size: 16px;
    white-space: normal;
  }
}

@media (max-width: 990px) {
  .expandedCategoryText {
    font-size: 13px;
  }
}

.expandedCategoryText {
  line-height: 1.5;
}

.radioButtonTitle {
	text-align: center;
	font-weight: bold;
}

.boldFormat {
	color: #152935;
	font-family: "IBM Plex Sans";
	font-size: 14px;
	font-weight: 500;
}

.red {
	color: #AD1625;
}

.blue {
	color: #3D70B2;
}

.green {
	color: #3d7117;
}

.pink {
	color: #e337e4;
}

.orange {
	color: #e88464;
}

.purple {
	color: #734098;
}

.gray {
	color: '';
}

.calendarIcon {
  margin-left: auto;
  margin-right: 2.5%;
}

.calendarContainer {
  border: 1px solid #dfe3e6;
  height: 500px;
  padding: 16px;
}

@media (max-width: 990px) {
  .calendar {
    width: 100%;
    height: 600px;
  }
}

@media (min-width: 991px) {
  .calendar {
    width: 1200px;
    height: 600px;
  }
}

.announcementEventCalendar {
  background-color: #c0e6ff !important;
  color: #325c80 !important;
}

.incidentEventCalendar {
  background-color: #ffd4a0 !important;
  color: #a53725 !important;
}

.maintenanceEventCalendar {
  background-color: #c8f08f !important;
  color: #2d660a !important;
}

.securityEventCalendar {
  background-color: #fde876 !important;
  color: #735f00 !important;
}

.alertEventCalendar {
  background-color: #eed2ff !important;
  color: #734098 !important;
}

.calendarAccordionContainer {
  padding-left: 0px;
}

.calendarAccordionContainer .bx--accordion__item {
  border-bottom-color: transparent;
  border-top-color: transparent;
  color: inherit;
}

.calendarAccordionContainer .bx--accordion__item .bx--accordion__heading {
  color: inherit;
  font-style: bold;
}

.calendarAccordionContainer .bx--accordion__item .bx--accordion__heading .bx--accordion__title {
  font-weight: bold;
}

.tagCellRow {
	display: block;
  align-items: center;
}

.tagCellRow > .bx--tag {
	display: flex !important;
	justify-content: center !important;
  margin-bottom: 1px;
}