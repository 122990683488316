.incidentNameHeaderCell {
    width: 50%;
    font-size: 15px;
    font-weight: bold;
    border: none;
    background-color: transparent;
    text-align: left;
    padding: 0.5rem;
}

.incidentHeaderCell {
    font-size: 15px;
    font-weight: bold;
    border: none;
    background-color: transparent;
    text-align: left;
    padding: 0.5rem;
}

.incidentExpandRow {
    border-bottom: 1px solid #dfe3e6;
}

@media (min-width: 991px) {
    .incidentExpandRow {
        height: 4rem !important;
        background-color: white;
    }
}

@media (max-width: 990px) {
    .incidentExpandRow {
        height: 3rem !important;
        background-color: white;
    }
}

.incidentExpandRow>td {
    vertical-align: middle;
    padding-left: 24px;
}