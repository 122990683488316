.headerOverflowMenu {
    width: 1.35rem;
    height: 3.75rem;
}

.footerItem {
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.5px;
    transition: opacity .2s ease 0s;
    line-height: 30px;
    padding: 0 15px;
    color: #fff;
}

.maintenancePageArea {
    margin-top: 15%;
    text-align: center;
}

.maintenancePageMainText {
    font-size: xx-large;
    font-weight: bolder;
    margin-bottom: 5%;
}

.maintenancePageMainButton {
    font-size: x-large;
    font-weight: bold;
    min-width: 15%;
}

.headerLink {
    cursor: pointer;
}