.chartTile {
  margin-top: 12px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.dashMenuTile {
  vertical-align: top;
  height: 290px;
  width: 100%;
  word-wrap: break-word;
  padding-top: 25px;
}

.information-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.informatioon-item {
  flex-grow: 1;
  flex-shrink: 1;
}

.ccc--dashboard-type-caption {
  font-size: 15px;
}

img {
  float: left;
  padding-right: 15px;
}

.dashMenuTitle {
  height: 75px;

}

.dashMenuText {
  height: 120px;
}

.pieLegend {
  display: block;
  height: 300px;
}

.pie {
  border-right-style: solid;
  border-right-width: thin;
  border-right-color: #E7E6EB;
  display: inline-block;
}

.buttons {
  vertical-align: top;
  margin-left: 10px;
  height: 250px;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}

.button {
  display: block !important;
}

.arc text {
  font: 12px sans-serif;
  font-weight: bold;
  text-anchor: middle;
  text-align: left;
}

.arc text.roll {
  visibility: hidden;
}

.marginCustomer {
  margin-left: 0px;
  margin-right: 500px;
}

.marginType {
  margin-left: 0px;
  margin-right: 80px;
}

.marginOS {
  margin-left: 0px;
  margin-right: 430px;
}

.marginSP {
  margin-left: 100px;
  margin-right: 50px;
}

.titleContainer {
  border-bottom: 1px solid grey;
  display: flex;
  justify-content: space-between;
}

.titleMessage {
  font-size: 28px;
  font-weight: bold;
}

.titleDate {
  display: flex;
  font-size: 15px;
  font-weight: 300;
  margin: 6px 0px 3px 0px;
  font-style: italic;
}

@media (min-width: 991px) {
  .dashboardComponentArea {
    display: flex;
  }
}

@media (min-width: 991px) {
  .serviceTypeTab {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@media (min-width: 991px) {
  .comboAndButtonArea {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: 6px;
  }
}

@media (max-width: 991px) {
  .comboAndButtonArea {
    display: grid;
  }
}

@media (min-width: 991px) {
  .spaceBetweenItems {
    width: 5%;
  }
}

@media (max-width: 991px) {
  .spaceBetweenItems {
    display: none;
  }
}

.audienceCombo {
  max-width: 50%;
}

@media (max-width: 991px) {
  .audienceCombo {
    margin-top: 6px;
  }
}

@media (max-width: 991px) {
  .filetypeCombobox {
    margin-top: 6px;
  }
}

@media (min-width: 991px) {
  .dashboardButton {
    font-size: 15px;
    float: right;
    margin-right: 12px;
  }
}

@media (max-width: 991px) {
  .dashboardButton {
    font-size: 15px;
    margin-top: 6px;
    width: 100%;
  }
}

.modalBodyWithOverflow {
  margin-bottom: 0rem;
  padding-bottom: 2rem;
  overflow-y: auto;
}

.containerModalBody {
  padding-top: 10px;
}

.containerContentSwitcher {
  display: flex;
  margin-bottom: 6px;
  justify-content: center;
}

@media (min-width: 991px) {
  .containerCheckboxes {
    display: flow-root;
    justify-content: center;
    margin-top: 18px;
  }
}

@media (max-width: 991px) {
  .containerCheckboxes {
    margin-top: 18px;
  }
}

.containerItems {
  margin-top: 16px;
}

.formItems {
  font-size: 16px;
  text-decoration: none;
}

.subscriptionInformationButton {
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
  color: #3d70b2;
  float: right;
  cursor: pointer;
}

@media (min-width: 991px) {
  .componentContainer {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 991px) {
  .columnComponentContainer {
    width: 47.5%;
  }
}

@media (min-width: 991px) {
  .commoncoreColumnComponentContainer {
    width: 100%;
  }
}

@media (max-width: 990px) {
  .pageComponentArea {
    padding-bottom: 12px;
  }
}

.spaceBetweenContainers {
  height: 18px;
}

@media (min-width: 991px) {
  .pageComponentTitle {
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid grey;
  }
}

@media (max-width: 990px) {
  .pageComponentTitle {
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 6px;
    border-bottom: 1px solid grey;
  }
}

.healthStatusContainer {
  margin-top: 6px;
  background-color: white;
  border: 1px solid #dfe3e6;
}

.noticeAPIModal {
  min-height: 100px;
}

.skeletonMultiSelectArea {
  height: 50px;
}

.regionTitle {
  color: #3d70b2;
}

.subscriptionCategoryAccordionContainer {
  padding-left: 0px;
  margin-top: 16px;
}

.subscriptionCategoryAccordionContainer .bx--accordion__item {
  border-bottom-color: transparent;
  border-top-color: transparent;
  color: inherit;
}

.subscriptionCategoryAccordionContainer .bx--accordion__item .bx--accordion__heading {
  color: inherit;
  font-style: bold;
}

.subscriptionCategoryAccordionContainer .bx--accordion__item .bx--accordion__heading .bx--accordion__title {
  font-weight: bold;
}

.bottomPadding {
  padding-bottom: 12px;
}

.noticeComboboxArea {
  width: 25%;
}

.errorMessage {
  color: red;
  font-weight: bold;
}

.currentSubscriptionText {
  font-weight: 500;
}

.cccMultiSelect>.bx--list-box__menu>.bx--list-box__menu-item {
  display: flex;
}

.cccMultiSelect>.bx--list-box__menu>.bx--list-box__menu-item>.bx--form-item.bx--checkbox-wrapper {
  display: flex;
  justify-content: center;
}