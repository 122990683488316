.statisticButtonRow {
  margin-bottom: 6px;
}

@media (max-width: 990px) {
  .statisticButtonRow {
    display: grid;
  }
}

.statisticConfirmationButtonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  grid-column: 1/-1;
  grid-row: -1/-1;
}

.statisticConfirmationButton {
  width: 100%;
  max-width: none;
  flex: 0 1 50%;
}

@media (min-width: 991px) {
  .statisticButton {
    font-size: 15px;
    margin-left: 6px;
  }
}

@media (max-width: 990px) {
  .statisticButton {
    font-size: 15px;
    margin-top: 6px;
    width: 100%;
  }
}

.timePickerChartArea {
  display: inline-block;
}

@media (min-width: 991px) {
  .timeOptionButtonsArea {
    padding: 12px 0px 6px 0px;
    text-align: center;
    justify-content: center;
  }
}

@media (max-width: 990px) {
  .timeOptionButtonsArea {
    padding: 12px 0px 6px 0px;
    text-align: center;
    display: inline-table;
  }
}

.timeControlOptionButton {
  margin: 5px !important;
  width: 23.5%;
}

.accordionContainer {
  background-color: white;
  padding-left: 0px;
}

.chartArea {
  margin-top: 21px;
  min-height: 300px;
  border: 1px solid lightgrey;
}

.chartNotUpdatedArea {
  display: flex;
  margin-top: 21px;
  min-height: 75px;
  align-items: center;
  justify-content: center;
  background-color: lightgrey;
  border: 1px solid black;
  text-align: center;
}

.chartAreaModal {
  margin: 21px 2.5% 0 0;
  width: 47.5%;
  vertical-align: top;
  display: inline-block;
  max-height: 700px;
  border: 1px solid #d3d3d3
}

.chartNotUpdatedAreaModal {
  margin: 21px 2.5% 0 0;
  width: 47.5%;
  min-height: 200px;
  display: inline-grid;
  align-items: center;
  background-color: lightgrey;
  border: 1px solid black;
  text-align: center;
}

.chartDataAreaModal {
  max-height: 120px;
  overflow-y: auto;
}

.chartDataAreaModal>.bx--structured-list.bx--structured-list--border {
  border: none;
}

.chartDataArea {
  max-height: 180px;
  overflow-y: auto;
}

.chartDataArea>.bx--structured-list.bx--structured-list--border {
  border: none;
}

.tableChartDataAreaModal {
  overflow-y: auto;
}

.tableChartDataAreaModal>.bx--structured-list.bx--structured-list--border {
  border: none;
}

.tableChartDataArea {
  overflow-y: auto;
}

.tableChartDataArea>.bx--structured-list.bx--structured-list--border {
  border: none;
}

.chartDataList {
  margin-bottom: 0px;
  min-width: 0;
}

@media (min-width: 991px) {
  .chartDataHeaderTitle {
    width: 65%;
  }
}

@media (min-width: 991px) {
  .chartDataHeaderCell {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
  }
}

@media (max-width: 990px) {
  .chartDataHeaderCell {
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    padding-left: 0px !important;
    cursor: pointer;
  }
}

.chartDataHeaderTitleText {
  display: inline-flex;
  width: 5rem;
  justify-content: center;
}

.chartDataHeaderTitleText > .bx--accordion__arrow {
  margin-left: 3px;
}

@media (min-width: 991px) {
  .chartDataTitleCell {
    font-size: 14px;
  }
}

@media (max-width: 990px) {
  .chartDataTitleCell {
    font-size: 9px;
  }
}

@media (min-width: 991px) {
  .chartDataCell {
    text-align: center;
    font-size: 14px;
  }
}

@media (max-width: 990px) {
  .chartDataCell {
    text-align: center;
    font-size: 9px;
  }
}

@media (max-width: 990px) {

  .bx--structured-list--selection .bx--structured-list-td:last-child,
  .bx--structured-list--selection .bx--structured-list-th:last-child {
    padding-right: 0.25rem;
  }
}

@media (max-width: 990px) {
  .bx--structured-list-td:last-child {
    padding-right: 0rem;
  }
}

.redText {
  padding: 6px;
  color: red;
}

.toolbarButtonChart {
  background-color: transparent;
  width: 3rem;
  min-width: 0rem;
  padding: 1rem;
}

.allChartsDownload {
  margin-left: 15px;
}

.chartDescriptionText {
  max-width: 800px;
  line-height: 1.5;
  text-align: justify;
}

.timeFilterTabsArea {
  padding: 6px;
}

.timeFilterTabsArea .bx--tabs__nav--hidden {
  justify-content: center;
}