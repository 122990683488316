$css--plex: true;

.app {
  margin-left: auto;
  margin-right: auto;
}

.app-header,
.app-main-content,
.app-sidebar,
.app-footer {
  padding: 1em;
}

.app-header {
  padding: 0em;
  display: flow-root;
}

.app-header-user-profile-container {

  /*  position in the main grid  */
  grid-area: user;

  /*  position in the main grid  */
  display: flex;
  flex-direction: column;

}

.app-header-user-profile-username {
  font-size: 12px;
  padding-top: 14px;
  color: #FFFFFF;
  padding-right: 1rem;
}

.app-header-user-profile-team {
  font-size: 12px;

}

.bx--select-input {
  text-align-last: right
}

.app-header-user-profile-pic {
  font-size: 12px;
}

.app-header-row-user {
  display: flex;
  flex-direction: row-reverse;
  height: 1.5rem
}

.app-header-row-team {
  display: flex;
  flex-direction: row-reverse;
  height: 3rem
}

.app-header-user-profile-team {
  font-size: 12px;
}

.app-header-user-profile-pic {
  font-size: 12px;
}

@media (min-width: 991px) {
  .app-main-content {
    margin: 1em;
    grid-area: main;
    min-height: 920px;
  }
}

@media(max-width: 990px) {
  .app-main-content {
    margin: 1em;
    grid-area: main;
  }
}

.app-sidebar {
  grid-area: sidebar;
  padding-left: 0px;
}

@media (min-width: 991px) {
  .app-footer {
    grid-area: footer;
    display: grid;
    bottom: 0px;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1.5fr));
    justify-content: center;
    color: white;
    background: #152935;
  }
}

@media (max-width: 990px) {
  .app-footer {
    grid-area: footer;
    display: grid;
    bottom: 0px;
    width: 100%;
    grid-template-columns: repeat(1fr);
    justify-content: center;
    color: white;
    background: #152935;
  }
}

@media (max-width: 990px) {
  .desktopOnly {
    display: none !important;
  }
}

.desktopOnly .bx--modal .bx--modal-container {
  min-width: 60%;
  max-width: 85%;
}

.desktopOnly .bx--modal .bx--modal-container .bx--modal-content {
  margin-bottom: 16px;
  overflow-y: hidden;
  font-size: 16px;
}

.desktopOnly .bx--modal .bx--modal-container .bx--modal-content.scrollY {
  overflow-y: auto;
}

@media (min-width: 991px) {
  .mobileOnly {
    display: none !important;
  }
}

.reverseRow {
  display: flex;
  flex-direction: row-reverse;
}

@media (min-width: 991px) {
  .filterContainer {
    margin-bottom: 6px;
  }
}

.filterArea {
  border: 1px solid grey;
  text-align: center;
}

@media (max-width: 990px) {
  .filterArea {
    margin-bottom: 1rem;
  }
}

.datePickerContainer {
  margin-top: 18px;
  display: flex;
}

@media (min-width: 991px) {
  .datePickerContainer {
    justify-content: space-around;
  }
}

@media (min-width: 991px) {
  .dateArea {
    width: 100%;
    margin-bottom: 12px;
    font-weight: bold;
  }
}

@media (max-width: 990px) {
  .dateArea {
    width: 100%;
    font-size: 70%;
    justify-content: center;
    font-weight: bold;
  }
}

@media (max-width: 990px) {
  .datePickerArea {
    margin-bottom: 12px;
  }
}

.datePickerArea .bx--form-item {
  align-items: center;
}

.bx--accordion__content {
  padding: 0 1rem 0 1rem;
}

@media (min-width: 991px) {
  .modalIcon {
    cursor: pointer;
  }
}

@media (max-width: 990px) {
  .modalIcon {
    display: none;
  }
}

@media (min-width: 991px) {
  .bx--modal-container {
    min-width: 65%;
    max-width: 95%;
    max-height: 95%;
  }
}

.bx--modal-content {
  font-size: 0.925rem;
}

.scrollY {
  overflow-y: scroll;
}

@media (max-width: 990px) {
  .bx--date-picker__input {
    font-size: 70%;
  }
}

@media (max-width: 990px) {
  .bx--time-picker__input-field {
    font-size: 70%;
  }
}

.timePicker {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;
  font-size: 0.875rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f4f4f4;
  border: none;
  width: 4.875rem;
  color: #152935;
  height: 2.45rem;
  padding: 0 1rem;
  -webkit-box-shadow: 0 1px 0 0 grey;
  box-shadow: 0 1px 0 0 grey;
}

.timePicker:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 990px) {
  .timePicker:last-child {
    margin-bottom: 1rem;
  }
}

.flex {
  display: flex;
}

.borderBlack {
  border: 1px solid black;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
  border-left: 1px solid black;
}

.padding0 {
  padding: 0;
}

.height16 {
  height: 16px;
}

.height50 {
  height: 50px;
}

.height400 {
  height: 400px;
}

.italic {
  font-style: italic !important;
}

.rbc-toolbar button {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  letter-spacing: 0;
  cursor: pointer;
  background-color: transparent;
  border-style: solid;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 0.875rem;
  font-weight: 600;
  min-height: 2.5rem;
  padding: 0 1rem;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  transition-duration: 250ms;
  transition-timing-function: ease-in;
  white-space: nowrap;
  line-height: 16px;
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: 300;
  text-align: center;
}

.rbc-header {
  min-height: 30px !important;
  font-size: 100% !important;
  padding: 3px !important;
  border-radius: 4px;
}

.rbc-month-view {
  border-radius: 4px;
}

.rbc-time-view {
  border-radius: 4px;
}

.rbc-agenda-view {
  border-radius: 4px;
  overflow: scroll;
}

.rbc-agenda-content {
  border-radius: 4px;
}

.bold {
  font-weight: bold;
}

.red {
  color: red;
}

.bx--btn--secondary+.bx--btn--primary,
.bx--btn--tertiary+.bx--btn--danger--primary {
  margin-left: 0px;
}

@media screen and (min-width: 768px) {
  .bx--tabs__nav-item+.bx--tabs__nav-item {
    margin-left: 1.5rem;
  }
}

.horizontalScroll {
  overflow-x: auto;
  overflow-y: hidden;
}

.textAlignCenter {
  text-align: center;
}

.width50 {
  width: 50%;
  max-width: 50%;
}

.width100 {
  width: 100%;
}

.justifyCenter {
  justify-content: center;
}

.flexWrap {
  flex-wrap: wrap;
}

.verticalAlignMiddle {
  vertical-align: middle;
}

.backgroundColor {
  background-color: #f4f7fb !important;
}

.cccSearch {
  background: white;
  height: 40px;
  font-size: 0.95rem;
}

.rbc-agenda-empty {
  margin-bottom: 6px;
}

.noBorder td {
  border: none !important;
}

.bx--tooltip__trigger svg {
  fill: #3d70b2;
}

.bx--content-switcher-btn::after {
  background-color: transparent;
}

.bx--search-magnifier {
  position: absolute;
  margin-left: 12px;
}

.bx--tabs__nav--hidden {
  text-align: center;
}

.bx--table-expand-v2__button {
  border: none;
  background-color: transparent;
}

.bx--data-table-v2 {
  width: 100%;
  border: 1px solid #dfe3e6;
  border-bottom: none;
}

.bx--data-table-v2 thead {
  background-color: #E0E0E0;
}

.bx--data-table-v2 td:first-of-type,
.bx--data-table-v2 th:first-of-type {
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.rbc-calendar {
  width: 100% !important;
}

.bx--date-picker.bx--date-picker--single .bx--date-picker__input {
  width: 10rem;
}

.bx--tabs__nav-link {
  width: 100% !important;
  min-width: 10rem;
}

.bx--list-box__menu-item {
  padding: 12px;
}

.cccCombobox {
  min-height: 3rem;
}

.notificationsTableExpandRow {
  border-bottom: 1px solid #dfe3e6;
}

@media (min-width: 991px) {
  .notificationsTableExpandRow {
    height: 4rem !important;
    background-color: white;
  }
}

@media (max-width: 990px) {
  .notificationsTableExpandRow {
    height: 3rem !important;
    background-color: white;
  }
}

.notificationsTableExpandRow>td {
  vertical-align: middle;
}

@media (min-width: 991px) {
  .notificationsTableExpandRowBanner {
    height: 6rem !important;
    background-color: #3d70b2;
  }
}

@media (max-width: 990px) {
  .notificationsTableExpandRowBanner {
    height: 4rem !important;
    background-color: #3d70b2;
  }
}

.notificationsTableExpandRowBanner .bx--table-expand-v2 .bx--table-expand-v2__svg {
  fill: white;
}

.bx--multi-select:not(.bx--list-box--expanded) .bx--list-box__menu {
  visibility: visible;
}

.downloadCSV {
  flex: 0 1 50%;
  max-width: none;
  width: 100%;
}

.downloadButton {
  width: 100%;
}

.pagination {
  border: 1px solid #dfe3e6;
}

@media (max-width: 991px) {
  .bx--btn {
    max-width: none;
  }
}

.bx--structured-list-td {
  padding: 0.75rem;
}

.bx--structured-list--selection .bx--structured-list-td:first-child,
.bx--structured-list--selection .bx--structured-list-th:first-child {
  text-align: left;
}

.bx--structured-list--selection .bx--structured-list-td,
.bx--structured-list--selection .bx--structured-list-th {
  text-align: center;
}

.bx--accordion__arrow {
  transition-duration: 0.25s;
}

.componentArrow {
  transition-duration: 0.25s;
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 1rem;
  height: 1rem;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
  margin: 2px 1rem 0 0;
  fill: #161616;
}