.bannerContainer {
  border: 1px solid#3d70b2;
  margin-bottom: 24px;
  background-color: #3d70b2;
}

.bannerTitle {
  margin: 6px 6px 24px 6px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.bannerBody {
  margin: 0px 6px 6px 6px;
  font-size: 16px;
  text-align: center;
  color: white;
  white-space: pre-line;
}

.alertWarning {
  background-color: #ead57c;
  width: 100%;
}

.alertWarning .bx--inline-notification__details .bx--inline-notification__text-wrapper .bx--inline-notification__title {
  padding-right: 12px;
}

.bx--toast-notification__close-button {
  background-color: transparent;
}