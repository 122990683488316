.performanceTablesDataContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.performanceTablesDataDisplay {
    display: contents;
}


.dataTile{
    width: 18%;
    height: 100px;
    margin: 6px;
    text-align: center;
    border: 1px solid #dfe3e6;
    background-color: white;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.dataTitle {
   /* margin: 0;
    padding-bottom: 0;*/
    margin-bottom: 10px; 
    font-weight: bold;
    font-size: small;
    max-height: 5px;
}

.dataValue{
    font-size: x-large;
    margin-bottom: 0px;
    justify-content: center;
    display: flex;
}

.dataValue>.bx--loading {
    padding-top: 3px;
}

.dataAllServersTile {
    border: 1px solid #dfe3e6;
    width: 31.5%;
    max-width: 31.5%;
    margin: 6px;
    text-align: center;
}

.dataAllServersTitle {
    margin: 2px 0px 12px 0px;
    color: white;
    font-weight: bold;
    font-size: large;
    
}

.dataAllServersValue {
    display: flex;
    margin-bottom: 6px;
    color: white;
    font-size: medium;
    justify-content: center;
}

.dataWarningDetailsTile {
    width: 100%;
    max-width: 100%;
    margin: 6px;
    text-align: center;
    border: 1px solid #dfe3e6;
    background-color: white;
}

.dataWarningDetailsTitle {
    margin: 2px 0px 12px 0px;
    font-weight: bold;
    font-size: medium;
}

.dataWarningDetailsValue {
    padding-top: 12px;
    justify-content: center;
    text-align: center;
}

.warningDetailColumn {
    display: flex;
    margin-bottom: 2px;
    width: 100%;
}

.warningDetailCell {
    display: flex;
    border-bottom: 1px solid grey;
    width: 100%;
    margin-bottom: 6px;
    font-size: small;
    justify-content: center;
}

.performanceTableArrow {
    margin: 0px !important;
    padding: 0px !important;
    rotate: 270deg;
}

.bigTableDataTableToolbar {
    padding-top: 0px;
    margin-bottom: 12px;
    display: inherit !important;
}

.bigTableFilterContainer>.bx--accordion__content {
    padding: 0px;
}

.bigTableAccordionContainer {
    padding-left: 0px;
}
 .serverstatus {
  width: 100%;
  padding-top: 20px;
}
.serverstatus > .bx--skeleton__placeholder {
  width: 100%;
}